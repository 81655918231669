$primary: #012169;
$secondary: #465d70;
$tertiary: #008EDB;
$errorBckgrd: #fcbeb8;
$errorColor: #7e0c00;

/*gestion par variable des couleurs principales*/
$kendo-color-primary: #012169;
$kendo-color-secondary: #465d70;

@import "~@progress/kendo-theme-default/dist/all.scss";
@font-face {
  font-family: "Montserrat-Regular";
  src: url("./fonts/Montserrat-Regular.ttf") format("truetype");
}

html,
body,
#root {
  height: 100vh;
}

body {
  margin: 0;
  font-family: "Montserrat-Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span,
p,
td,
.k-label,
.k-button {
  font-size: 1.1em;
}

h3 {
  font-size: 1.4em;
}

#root {
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
}

.text-color-primary {
  color: $primary;
}
.text-color-white {
  color: #ffffff;
}
.background-color-primary {
  background-color: $primary;
}
.background-color-default {
  background-color: #f6f6f6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.center-page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-fill {
  flex: 1 0 !important;
}

.sticky-footer {
  position: sticky;
  bottom: 0;
  padding: 16px 0;
  background-color: #f8f8f8;
  z-index: 1;
}

#notification-position {
  position: absolute;
  bottom: 0;
  left: auto;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 16px;
}

.k-tabstrip.tab-strip-responsive .k-tabstrip-items {
  display: flex;
  flex-direction: column;
}

.k-content .k-animation-container {
  width: 100%;
}

.cgu-dialog,
.info-user {
  a {
    font-weight: bold;
    color: $primary;
  }
}

/*
  Error message
*/
.error-box {
  display: flex;
  flex-flow: column !important;
  align-items: flex-start !important;
  font-size: 14px;
}

.input-error {
  background-color: rgba(255, 99, 88, 0.25);
  cursor: default;
}

.button-error {
  background-color: transparent;
  background-image: none;
  border: none;
  color: #7e0c00;
  text-decoration: underline;
  &:hover {
    color: #7e0c00;
    background-color: transparent;
    background-image: none;
  }
  &:focus {
    box-shadow: none;
  }
}

.button-join {
  padding: 4px 1px 0px 0px;
}

.span-join {
  padding: 0px 0px 0px 8px;
}

/*
  Definitions for the grid system, cards and containers.
*/
.grid-wrapper {
  display: grid;
  grid-gap: 16px;
}

.grid-wrapper-form-2 {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
}

.grid-wrapper-form-2-custom {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 3fr;
}

.grid-wrapper-form-3 {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(3, 1fr);
}

.grid-wrapper-form-3-custom {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 3fr 1fr 3fr;
  place-items: center;
}

.grid-wrapper-form-3-3-1 {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 3fr 3fr 1fr;
}

.grid-wrapper-form-4 {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(4, 1fr);
}

.grid-wrapper-form-4-custom {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr 2fr 3fr;
}

.grid-wrapper-form-1-2-2-4 {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 2fr 2fr 2fr 3fr;
}

.grid-wrapper-form-5 {
  display: grid;
  grid-gap: 16px;
}

.k-grid-header {
  padding: 0 !important;
}

.grid-editable {
  .k-grid-header {
    background-color: $primary !important;
    color: $primary !important;
  }
  tr.k-alt {
    background-color: rgba(($primary), 0.1);
    &:hover {
      background-color: rgba(($primary), 0.3);
    }
  }
  tr.k-master-row {
    &:hover {
      background-color: rgba(($primary), 0.3);
    }
  }
  .k-grid-edit-row {
    background-color: rgba(($primary), 0.4) !important;
  }
}

.k-state-disabled {
  background-color: #f6f6f6ab !important;
  color: black !important;
}

span.checkbox.k-state-disabled {
  max-height: 1em;
}

button.k-state-disabled {
  color: #656565 !important;
  border-color: rgba(0, 0, 0, 0.06) !important;
}

.panelbar-editable {
  .k-item > .k-link {
    background-color: $primary !important;
    color: white !important;
    .k-icon {
      color: #ffffff;
    }
  }
}

.display-column-or-row {
  display: flex;
  flex-direction: column;
}

.show-content {
  display: none;
}

.grid-elevage,
.grid-structure,
.grid-saisie-dm,
.grid-saisie-da,
.grid-paie-de-lait,
.grid-fourrage-chevre,
.grid-aliment-chevre,
.grid-fourrage-chevrette,
.grid-aliment-chevrette,
.grid-fourrage-bouc,
.grid-aliment-bouc,
.grid-admin {
  .k-grid-container > .k-grid-content {
    overflow: auto;
  }
}

.grid-paie-de-lait-dm {
  .k-grid-content {
    max-height: calc(100vh - 20rem) !important;
  }
}

.k-numerictextbox-align-right {
  text-align: right;
  input.k-input {
    text-align: right;
  }
}

.saisie-dm,
.grid-saisie-da .k-grid-header {
  background-color: $primary !important;
  color: #ffffff;
}

.tab-donnees-mensuelles {
  input {
    text-align: right;
  }
}

.tab-donnees-annuelles {
  .k-link {
    font-weight: bold !important;
  }
  input {
    text-align: right;
  }
  span.k-numerictextbox,
  input.k-textbox {
    max-width: 150px;
  }

  .grid-place-items-center {
    place-items: center !important;
  }
}

th.k-header .k-link {
  text-align: right;
  background-color: $primary;
  color: #ffffff;
}

.k-grid.saisie-dm .k-grid-header th:last-child span {
  text-align: center;
}

.k-panelbar {
  span.k-header {
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .k-animation-container {
    z-index: auto !important;
  }
}

.k-grid-header .k-header > .k-link {
  margin: -8px -7px;
}

.chip-statut {
  color: $primary;
  border-color: $primary;
  border-radius: 50px;
  padding: 8px;
  font-weight: bold;
  opacity: 1 !important;
  outline: none !important;
  font-size: 16px;
  height: 35px;
  filter: none !important;
}

.k-daterangepicker-wrap {
  display: flex !important;
  .k-textbox-container {
    margin-right: 16px;
  }
}

.admin-filter-elevage {
  .k-textbox-container {
    width: 100%;
  }
}

.k-grid .k-table-row.k-table-alt-row {
  background-color: rgba($tertiary, 0.05);
}


/* Form errors */
.panel-error {
  .k-panelbar > .k-panelbar-header > .k-link,
  .k-panelbar > .k-panelbar-header > .k-link {
    color: $errorColor;
    background-color: $errorBckgrd;
  }

  .k-header {
    font-size: 14px;
    font-weight: bold !important;
  }

  .k-panelbar > .k-item > .k-link.k-state-selected {
    color: $errorColor;
    background-color: $errorBckgrd;
  }

  .k-panelbar > .k-item > .k-link.k-state-selected:hover {
    color: $errorColor;
    background-color: $errorBckgrd;
  }

  .k-content {
    color: $errorColor;
    background-color: $errorBckgrd;
    padding-left: 10px;
  }
}

/**
 *CSS Workaround for Kendo UI Issue https://github.com/telerik/kendo-react/issues/45
 */
.k-animation-container {
  z-index: 10003;
}

/* login */
.login {
  background: $primary;
  .k-label {
    color: #0a0b10;
  }
  .k-card-header {
    padding: 40px;
  }
  .k-card-footer {
    padding: 0;
    border: none;
  }
  .k-form-buttons {
    text-align: center;
    width: 100%;
    margin-top: 20px;
    .k-button {
      color: #ffffff;
      background: $primary;
      margin: auto;
    }
    .k-button.k-state-disabled {
      color: inherit;
    }
  }
  .k-textbox-container {
    margin-top: 10px;
  }
  .k-textbox-container.k-state-empty > .k-label,
  .k-floating-label-container.k-state-empty > .k-label {
    top: 0 !important;
    left: 0 !important;
  }
  // Fix pour le login kendo 5
  .k-textbox-container.k-empty > .k-label,
  .k-floating-label-container.k-empty > .k-label {
    top: 0 !important;
    left: 0 !important;
  }
}

/* Menu */
.k-appbar-section {
  .menu {
    display: flex;
    .k-menu-link:hover {
      color: #656565;
    }
  }
  .k-item {
    color: $secondary;
  }

  color: $secondary;
  a,
  button.k-button {
    text-decoration: none;
    color: $primary;
    button.k-button {
      color: $primary;
    }
  }
}

// utils

// @each $size, $val in $spacing {
//   .k-gap-#{$size} {
//     gap: $val;
//   }
// }

.ch-dropdowntree > .k-popup {
  max-height: 400px;
}

.k-ml-auto {
  margin-left: auto;
}

.k-mr-auto {
  margin-right: auto;
}

.required {
  color: red;
  font-size: 20px;
}

@media screen and (max-width: 1080px) {
  .grid-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-wrapper-form-2 {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-wrapper-form-5 {
    display: grid;
    grid-gap: 16px;
  }
  .k-tabstrip.tab-strip-responsive .k-tabstrip-items {
    display: flex;
    flex-direction: row;
  }

  .display-column-or-row {
    display: flex !important;
    flex-direction: column !important;
  }

  .align-item-start {
    align-items: start;
  }
}

@media screen and (min-width: 1080px) {
  .grid-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid-wrapper-form-2 {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-wrapper-form-5 {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(5, 1fr);
  }
  .k-tabstrip.tab-strip-responsive .k-tabstrip-items {
    display: flex;
    flex-direction: row;
  }
  .display-column-or-row {
    display: flex;
    flex-direction: row;
  }

  .show-content {
    display: block;
  }
}

@media only screen and (max-width: 1300px) {
  .username-menu {
    display: none;
  }
}

.k-icon.k-i-sort-asc-sm,
.k-icon.k-i-sort-desc-sm {
  color: white !important;
}

.text-decoration-none {
  text-decoration: none;
}
.text-decoration-underline\:hover:hover {
  text-decoration: underline;
}

.hide-action {
  .k-upload .k-actions {
    display: none;
  }
}

.img-logo {
  max-width: 328px;
  max-height: 328px;
}

// pdf
.the-pdf {
  --cell-height: 0.6cm;
  --pdf-width: 21cm;
  --pdf-margin: 5mm;
  --table-space: 2mm;
  --zoom: 100;

  transform: scale(calc(var(--zoom) / 100));
  transform-origin: top left;

  margin: var(--pdf-margin);
  width: calc(var(--pdf-width) - (2 * var(--pdf-margin)));
  font-size: 9px;

  *, ::before, ::after {
    border-width: 0;
    border-style: solid;
    border-color: #666;
  }

  .bg-gray-100 {
    background-color: #eeeeee;
  }
  .bg-white {
    background-color: #fff;
  }
  .border {
    border-width: 1px;
  }
  .border-x {
    border-left-width: 1px;
    border-right-width: 1px;
  }
  .border-y {
    border-top-width: 1px;
    border-bottom-width: 1px;
  }
  .border-t {
    border-top-width: 1px;
  }
  .border-r {
    border-right-width: 1px;
  }
  .border-b {
    border-bottom-width: 1px;
  }
  .border-l {
    border-left-width: 1px;
  }
  .border-b-transparent {
    border-bottom-color: transparent;
  }
  .pr-1 {
    padding-right: 0.25rem;
  }
  .px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .ml-1 {
    margin-left: 0.5mm;
  }
  .ml-2 {
    margin-left: 2mm;
  }
  .w-60 {
    width: 6cm;
  }
  .w-96 {
    width: 9cm;
  }
  .float-right {
    float: right;
  }

  .table-wrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: "flex-start";
    gap: var(--table-space);
  }
  table, th, td {
    border-collapse: collapse;
  }
  table {
    border-collapse: collapse;
    tr {
      height: var(--cell-height)
    }
    tbody.separate {
      &::before {
        line-height: var(--table-space);
        content:"@";
        color:transparent;
        display:block;
      }
      &.fix::before {
        line-height: calc(var(--table-space) - 0.5px);
      }
    }
  }

  .flux-bilan {
    margin-top: 1cm;
    display: flex;
    justify-content: center;

    break-after: page;
    > table {
      width: 12cm;
    }

  }
  .mini-chart {
    display: flex;
    justify-content: space-between;
    height: 7.5cm;

    break-after: page;
    .mini-chart-width {
      width: 45%;
    }
  }
}

// Pdf DMA
.pdf-dma {
  --zoom: 100;
  --pdf-width: 21cm;
  --pdf-margin: 5mm;
  margin: var(--pdf-margin);
  width: calc(var(--pdf-width) - (2 * var(--pdf-margin)));

  transform: scale(calc(var(--zoom) / 100));
  transform-origin: top left;
}

.pdf-dma-table {
  --cell-height: 0.6cm;
  --border: 1px solid black;
  --width-small-td: 1cm;
  --width-large-td: 6cm;
  --height-th: 1cm;
  --height-td: 1.4cm;

  display: flex;
  flex-direction: row;

  .mt-40{
    padding-top: 40px;
  }

  .border-l{
    border-left: var(--border);
  }
  .border-t{
    border-top: var(--border);
  }

  .numero{
    text-align: center;
    width: var(--width-small-td);
  }
  .texte{
    padding-left: 2px;
    width: var(--width-large-td);
  }

  table{
    margin: 40px;
    border-spacing: 0;
  }

  th{
    font-size: 12px;
    height: var(--height-th);
  }

  td{
    font-size: 11px;
    height: var(--height-td);
  }

  td, th{
    border-right: var(--border);
    border-bottom: var(--border);

  }

  tbody.separate{
    break-after: page;
  }
}

// Pdf annuel
.pdf-annuel {
  font-size: 11px;
  --zoom: 100;
  --pdf-width: 21cm;
  --pdf-margin: 5mm;
  margin: var(--pdf-margin);
  width: calc(var(--pdf-width) - (2 * var(--pdf-margin)));

  transform: scale(calc(var(--zoom) / 100));
  transform-origin: top left;
}

.pdf-annuel-page1{
  font-size:11px;
  break-after: page;

  .ligne-marge{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-size: 11px;
    padding-top: 2px;
    padding-bottom: 2px;

    align-items: center;
    width: 25%;
  }


  .ml-5{
    margin-left: 5px;
  }
  .ml-40{
    margin-left: 40px;
  }

  .mr-10{
    margin-right: 10px;
  }
  .mr-40{
    margin-right: 40px;
  }

  .fb{
    font-weight: bold;
  }

  .ligne{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-size: 11px;

    .valeur-ligne{
      border-left:1px solid black;
      flex-basis:60px;
      text-align:center;
      padding-top: 2px;
      padding-bottom: 2px;
    }

    .intitule-ligne{
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }

  .border-t {
    border-top: 1px solid black
  }
  .border-b{
    border-bottom: 1px solid black
  }
  .border-r{
    border-right: 1px solid black
  }
  .border-l {
    border-left: 1px solid black
  }

  .bloc{
    display: flex;
    flex-direction: column;
  }

  .bloc-gd{
    display: flex;
    flex-direction: row;

  }

  .bloc-gauche{
    flex: row;
    width: 50%;
    margin-left: 40px;
    border-left: 1px solid black;
    border-right: 1px solid black;

  }

  .bloc-droit{
    margin-right: 40px;
    flex: row;
    border-right: 1px solid black;
    width: 50%;
  }

  .titre{
    font-size: 13px;
    text-align: center;
    margin: 5px;
  }
}


.pdf-annuel-page2{
  display: flex;
  flex-direction: row;

  margin-top: 2cm;
  margin-left: 0.5cm;

  .div-flex{
    display:flex;
    flex-direction:row;
  }

  .chart-produit{
    height: 4.5cm;
    width: 4cm;
  }

  .chart-structures{
    height: 6cm;
    width: 4cm;
  }

  .chart{
    margin-left: 2cm;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .margeBrute{
    height: 1cm;
  }

  .margeNette{
    height: 1.2cm;
  }

  .pctMarge{
    border: 1px solid black;
    width: 3cm;
    margin-left: 0.5cm;
    margin-top: 0.5cm;
    padding: 1px;

    font-size: 15px;
    font-weight: bold;

    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }

  .valeur{
    width: 1.5cm;
    text-align: center;
  }

  .intitule{
    width: 3.5cm;
    border-left: 1px solid black;
  }

  .total{
    font-weight: bold;
    border-bottom: 1px solid black;
    height: 1cm;
  }

  table{
    border-spacing: 0;
  }

  td, th{
    height: 0.7cm;
    border-top: 1px solid black;
    border-right: 1px solid black;
  }

  tbody.separate {
    &::before {
      line-height: 0.5cm;
      content:"@";
      color:transparent;
      display:block;
    }
  }

  td.separate{
    width: 0.5cm;
    border-left: 0;
    border-bottom: 0;
    border-top: 0;
  }

  th.separate{
    border-top: 0;
  }

}

.k-checkbox:checked {
  background-color: $tertiary;
}

